import React from "react";
import { Link } from "@quintype/components";
import styles from "./styles.m.css";
import PropTypes from "prop-types";
import { Icon } from "../../atoms/icon";
import { get, throttle } from "lodash";
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
const MenuItem = ({ title, path, url, isExternallink }) => {
  url = path || url;
  return (
  <li itemprop="name" className={styles["menu-item"]}>
    <Link itemprop="url" aria-label="Read more" href={url} className={styles["menu-item-link"]} externalLink={isExternallink && url}>
      {title}
    </Link>
  </li>
);}
MenuItem.propTypes = {
  title: PropTypes.string,
  isExternallink: PropTypes.bool,
  url: PropTypes.string
};

class Subject {
  constructor() {
    this.listeners = [];
  }
  observe(listener) {
    this.listeners.push(listener);
  }

  updateObservers() {
    this.listeners.forEach(list => {
      const rect = ReactDOM.findDOMNode(list.current).getBoundingClientRect();
      list.current.updateChild(rect);
    });
  }
}

const subject = new Subject();

class RootMenuItem extends React.Component {
  constructor(props) {
    super(props);
    this.root = null;
    this.expand = this.expand.bind(this);
    this.collapse = this.collapse.bind(this);
    this.state = {
      showMenu: false
    };
    this.childMenu = React.createRef();
  }

  expand() {
    // console.log("menu---->", this.state.showMenu);
    // this.setState({ showMenu: !this.state.showMenu });
    this.setState({ showMenu: true });
    subject.updateObservers();
  }

  collapse() {
    this.setState({ showMenu: false });
  }



  render() {
    const { id, items, title } = this.props;
    return (
      <li className={`${styles["root-menu-element"]}`} key={id} onMouseEnter={this.expand} onMouseLeave={this.collapse} onClick={this.expand}>
        <span className={styles["menu-item-expandable"]}>
          {title}

          {items && Object.getPrototypeOf(items) === Array.prototype && items.length > 0 ? (
            <div className={styles["menu-drop-down"]}>
              <MenuGroup items={items} isMenuOpen={this.state.showMenu} />
            </div>
          ) : null}
        </span>
      </li>
    );
  }
}

RootMenuItem.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  id: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.shape(MenuItem.propTypes))
};

const MenuGroup = ({ items, isMenuOpen, className, sectionIcon, firstElement, sectionpageMenu}) => {
  const ulMarkup = (
    <ul
    itemScope id="topnav" itemtype="https://schema.org/SiteNavigationElement" role="menu" className={`${styles["navbar"]} ${className || ""} ${isMenuOpen ? styles["expand"] : styles["collapse"]}`}

    >
      {firstElement ? <li itemprop="name" role="menuitem"> {firstElement} </li> : null }
      {items.map(item => {
        if (
          typeof item === "object" &&
          item.hasOwnProperty("items") &&
          item.items instanceof Array &&
          item.items.length > 0
        ) {
          const ref = React.createRef();
          subject.observe(ref);
          return <RootMenuItem {...item} key={item.id} ref={ref} />;
        } else {
          return <MenuItem {...item} key={item.id} />;
        }
      })}
    </ul>
  );
  return ulMarkup;
};

MenuGroup.propTypes = {
  isMenuOpen: PropTypes.bool,
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape(MenuItem)),
  sectionIcon: PropTypes.shape(Icon)
};

const SecondaryMenu = ({ menuGroup, communityColor, slug, className, firstElement, communityIcons, parentSection, sectionpageMenu }) => {
  const iconType = `comm-${slug}`;
  let menuItems = get(menuGroup, ["items"], []);
  let sectionIcon = null;
  if (communityIcons) {
    sectionIcon = slug && communityIcons[slug] ? <Link className={styles["menu-item-link"]} href={get(parentSection, ["section-url"])}> <Icon type={iconType} className={`${styles["section-icon"]} ${styles[slug]}`} /> </Link>: null;
  } else {
    sectionIcon = slug ? <Link itemprop="url" className={styles["menu-item-link"]} href={get(parentSection, ["section-url"])}><Icon type={iconType} className={`${styles["section-icon"]} ${styles[slug]}`} /> </Link>: null;
  }
  return menuItems.length > 0 ? (
    <div className={className}>
      <div className={`${styles["secondary-menu-mobile"]}`}>
        <nav
        className={`${styles["nav-bar"]} ${className || ""} ${sectionpageMenu ? styles["sec-nav"] : styles["sec-nav-bar"]}`} style={{ "--accent-color": communityColor }}
        >
          <div className="container">
          <MenuGroup
            items={menuItems}
            className={styles["root-menu-item"]}
            sectionIcon={sectionIcon}
            firstElement={firstElement}
            sectionpageMenu={sectionpageMenu}
          />
          </div>
        </nav>
      </div>
    </div>
  ) : null;
};

SecondaryMenu.propTypes = {
  parentSection: PropTypes.shape({
    "section-url": PropTypes.string
  }),
  communityColor: PropTypes.string,
  menuGroup: PropTypes.shape(RootMenuItem),
  slug: PropTypes.string,
  className: PropTypes.string,
  firstElement: PropTypes.element,
  communityIcons: PropTypes.object,
  sectionpageMenu: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    communityIcons: get(state, ["qt","config","communityIcons"]),
    parentSection: get(state, ["qt","data","parentSection"]) || get(state, ["qt","data","section"])
  }
};
export default connect(mapStateToProps)(SecondaryMenu);
