import React from "react";
import PropTypes from "prop-types";
import MagazineIssueCardBase from "../../atoms/magazine-issue-bundle-card/index";
import styles from "./styles.m.css";
export const MagazineIssueOneSliderThreeCards = ({ collection, prevIssue, nextIssue, config, magazine }) => {
  return (
    <div className={styles.base}>
      <div>
        <MagazineIssueCardBase
          className={styles.issue}
          collection={collection}
          prevIssue={prevIssue}
          nextIssue={nextIssue}
          config={config}
          magazine={magazine}
        />
      </div>
    </div>
  );
};

MagazineIssueOneSliderThreeCards.propTypes = {
  collection: PropTypes.any,
  prevIssue: PropTypes.any,
  nextIssue: PropTypes.any,
  magazine: PropTypes.any,
  config: PropTypes.shape({
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        slug: PropTypes.string
      })
    ),
    magazines: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        magazineSlug: PropTypes.string,
        name: PropTypes.string,
        url: PropTypes.string,
        vikatanMagazineId: PropTypes.number
      })
    ),
    "publisher-name": PropTypes.string
  })
};
MagazineIssueOneSliderThreeCards.storyLimit = 8;

export default MagazineIssueOneSliderThreeCards;
