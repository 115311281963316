import React from "react";
import MagazineRow from "../../rows/magazine-row";
import styles from "./styles.m.css";
import PropTypes from "prop-types";
import { ReadMoreLink } from "../../atoms/read-more-link";
import { WebEngage, GA, PGAD } from "../../../integrations";
import CollectionTitleWithCrossLine from "../../atoms/CollectionTitleWithCrossLine";
import { get } from "lodash";
import { PAGE_TYPE } from "../../../constants";
import { VikatanSticky } from "../../rows/ad-free";
import AdUnit from "../../atoms/ad-unit";

const MagazineListingRow = ({ magazine, showHeadLine = true, numberOfItems = 5, entities }) => {
  let issues = get(magazine, ["collections"], null);
  let id = get(magazine, ["id"], null);
  const magazineEntity = entities && entities.filter(magazine => magazine.id === id);
  const magazineView = get(magazineEntity, [0, "view"], 1);

  if (issues && magazineView) {
    const items = issues.slice(0, numberOfItems);
    const LoadMoreLink = <ReadMoreLink text={"மேலும் படிக்க"} href={`/archive?magazine=${magazine.id}`} />;
    return (
      <div className={"container"}>
        <div className={styles["base"]}>
          {showHeadLine ? <CollectionTitleWithCrossLine title={magazine.name} className={styles["title"]} /> : null}
          <MagazineRow
            issues={items}
            next={LoadMoreLink}
            className={`${styles["magazine-row"]} ${styles["stretch"]}`}
          />
        </div>
        <VikatanSticky placement="magazine" />
      </div>
    );
  } else {
    return null;
  }
};

MagazineListingRow.propTypes = {
  magazine: PropTypes.shape(MagazineRow.propTypes),
  numberOfItems: PropTypes.number,
  entities: PropTypes.shape(),
  showHeadLine: PropTypes.bool
};

function MagazineIssueListingPage({ data, config }) {
  React.useEffect(() => {
    WebEngage.registerPageView();
    GA.registerPageView(PAGE_TYPE.MAGAZINE_LISTING_PAGE);
    PGAD.loadScript();
  }, []);
  const magazinesShown = get(data, ["magazines"], null);
  const entities = get(data, ["entities"], {});

  return (
    <div className={`${styles["main-container"]}`}>
      {magazinesShown.map((magazine, index) => {
        return (
          <div key={index}>
            {index > 2 && index % 3 === 0 && <AdUnit index={index} />}
            <MagazineListingRow magazine={magazine} entities={entities} />
          </div>
        );
      })}
    </div>
  );
}

MagazineIssueListingPage.propTypes = {
  data: PropTypes.shape({
    magazines: PropTypes.arrayOf(PropTypes.shape(MagazineListingRow.propTypes)),
    entities: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        slug: PropTypes.string,
        name: PropTypes.string
      })
    )
  }),
  config: PropTypes.shape({
    magazines: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        id: PropTypes.number
      })
    )
  })
};

export { MagazineIssueListingPage, MagazineListingRow };
