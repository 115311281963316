import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { get } from "lodash";
import ResponsiveImageWithFallback from "../responsive-image-with-fallback";
import { generateImageSources } from "../../utils/utils";
import styles from "./styles.m.css";
import Modal from "react-modal";

import { EVOLOK } from "../../../integrations";

const MagazineIssueCardBase = ({
  collection,
  prevIssue,
  nextIssue,
  className = "",
  cardWithImageZoom = true,
  subscriptionHost = ""
}) => {
  const coverImageS3Key = get(collection, ["metadata", "cover-image", "cover-image-s3-key"], "");
  const coverImageMetaData = get(collection, ["metadata", "cover-image", "cover-image-metadata"], "");
  const imgAltText = get(collection, ["metadata", "magazine", "name"], "");
  let storyLink = get(collection, ["items", 0, "story", "url"], "");
  const [evAccess, setEvAccess] = useState("ALLOW_ACCESS");
  const [curUrl, setcurUrl] = useState("");

  useEffect(() => {
    Modal.setAppElement("body");
    EVOLOK.loadScript("magazine-page", { collection: collection }, handleMeteringSuccess);
    setcurUrl(window.location.href);
  }, []);

  const handleMeteringSuccess = response => {
    setEvAccess(response.result);
  };

  return (
    <div className={styles["base"]}>
      <div className={styles["text-wrapper"]}>
        <h5 className={`${styles["author"]}`}>{collection.name}</h5>
        <p className={`${styles["contributor"]}`}>{collection.summary}</p>
        {evAccess !== "ALLOW_ACCESS" ? (
          <div className={`${styles["buttons"]}`}>
            <a href={storyLink} className={`${styles["preview-btn"]}`}>
              Preview
            </a>
            <a
              href={`https://store.vikatan.com/vikatan/special_purchase.php?sid=${collection.id}&redirect_url=`.concat(
                encodeURIComponent(curUrl)
              )}
              className={`${styles["buy-btn"]}`}
            >
              Quick Buy
            </a>
          </div>
        ) : (
          ""
        )}
      </div>
      <ResponsiveImageWithFallback
        className={styles["image-wrapper"]}
        slug={coverImageS3Key}
        metadata={coverImageMetaData}
        alt={imgAltText}
        imgParams={{ auto: ["format", "compress"] }}
        sources={generateImageSources(
          { aspectRatio: [4, 3], screenWidthCoverage: 0.34 },
          { aspectRatio: [4, 3], screenWidthCoverage: 0.12 }
        )}
      />
    </div>
  );
};

MagazineIssueCardBase.propTypes = {
  className: PropTypes.string,
  subscriptionHost: PropTypes.string,
  cardWithImageZoom: PropTypes.bool,
  collection: PropTypes.shape({
    metadata: PropTypes.shape({
      "cover-image": PropTypes.shape({
        "cover-image-metadata": PropTypes.shape({
          height: PropTypes.number,
          width: PropTypes.number
        }),
        "cover-image-s3-key": PropTypes.string
      }),
      magazine: PropTypes.shape({
        name: PropTypes.string
      })
    })
  }),
  config: PropTypes.shape({
    sections: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        slug: PropTypes.string
      })
    ),
    magazines: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        magazineSlug: PropTypes.string,
        name: PropTypes.string,
        url: PropTypes.string,
        vikatanMagazineId: PropTypes.number
      })
    ),
    "publisher-name": PropTypes.string
  }),
  prevIssue: PropTypes.object,
  nextIssue: PropTypes.object
};

export const MagazineIssueBundleCard = connect(state => {
  return { subscriptionHost: get(state, ["qt", "config", "vikatanHosts", "subscription"]) };
})(MagazineIssueCardBase);

export default MagazineIssueBundleCard;
