import React, { useState, lazy, Suspense } from "react";
import styles from "./styles.m.css";
import Icon from "../../atoms/icon";
import Media from "react-media";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { WithClientSideOnly } from "@quintype/components";

const LazySelect = lazy(() => import(/* webpackChunkName: "react-select" */ "react-select"));
function Select(props) {
  return (
    <WithClientSideOnly>
      {({ clientSideRendered }) =>
        clientSideRendered && (
          <Suspense fallback={<span />}>
            <LazySelect {...props} />
          </Suspense>
        )
      }
    </WithClientSideOnly>
  );
}

const MagazineArchiveSearch = ({ magazineEntity, year, month, magazines, navigateTo }) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  const date = new Date();
  const [monthOption, setMonth] = useState({
    label: months[month] || months[date.getMonth()],
    value: parseInt(month, 10) + 1 || date.getMonth() + 1
  });
  const MagazineOptions = magazines.map(({ name, id }) => ({ label: name, value: id }));
  const [yearOption, setYear] = useState({ label: year || date.getFullYear(), value: year || date.getFullYear() });
  const magazineSelected = MagazineOptions.find(m => m.value === magazineEntity.id);
  const [magazineOption, setMagazine] = useState(magazineSelected);
  const [isOpen, setIsOpen] = useState(false);
  const monthOptions = months.map((month, index) => ({ label: month, value: parseInt(index, 10) + 1 }));

  const issueYear = [];
  for (let year = new Date().getFullYear(); year > 2005; year--) {
    issueYear.push({ label: year, value: year });
  }
  const toggleShow = () => {
    isOpen ? setIsOpen(false) : setIsOpen(true);
  };

  const hasValue = (attr, prop) => (prop && prop.hasOwnProperty("value") ? `&${attr}=${prop.value}` : "");
  const search = () => {
    navigateTo(
      `/archive?magazine=${magazineOption.value}${hasValue("year", yearOption)}${hasValue("month", monthOption)}`
    );
    setIsOpen(false);
  };

  return (
    <div className={`${styles["base"]}`}>
      <Media query="(max-width:768px)">
        <button onClick={toggleShow} className={`${styles["filter-btn"]} container`}>
          <span className={styles["float-left"]}>
            <Icon type="filter-icon" className={`${styles["filter-black"]}`} />
            Filter
          </span>
          <span className={styles["float-right"]}>
            {isOpen ? <Icon type="angle-up" /> : <Icon type="angle-down" />}
          </span>
        </button>
      </Media>
      <div className={`container ${styles["menu"]} ${isOpen ? styles["open"] : styles["hide"]}`}>
        <div className={styles["select-field"]}>
          <label className={styles["form-label"]}>Magazine</label>
          <Select
            options={MagazineOptions}
            onChange={setMagazine}
            value={magazineOption}
            classNamePrefix={"search"}
            placeholder={"Choose Magazine"}
            className={styles["select"]}
          />
        </div>
        <div className={styles["select-field"]}>
          <label className={styles["form-label"]}>Year</label>
          <Select
            options={issueYear}
            onChange={setYear}
            value={yearOption}
            classNamePrefix={"search"}
            placeholder={"Select Year"}
            className={styles["select"]}
          />
        </div>
        <div className={styles["select-field"]}>
          <label className={styles["form-label"]}>Month</label>
          <Select
            options={monthOptions}
            onChange={setMonth}
            value={monthOption}
            placeholder={"Select Month"}
            classNamePrefix={"search"}
            className={styles["select"]}
          />
        </div>
        <div className={styles["filter-container"]}>
          <button className={styles["filter"]} onClick={search}>
            <Media query="(min-width:768px)">
              <Icon type="filter-icon" className={styles["icon"]} />
            </Media>
            Filter
          </button>
        </div>
      </div>
    </div>
  );
};
const MagazinePropTypes = {
  collection: PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        headline: PropTypes.string.number,
        slug: PropTypes.string.number,
        id: PropTypes.string.number
      })
    )
  })
};
MagazineArchiveSearch.propTypes = {
  magazineEntity: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  }),
  year: PropTypes.string,
  month: PropTypes.string,
  magazines: PropTypes.arrayOf(PropTypes.shape(MagazinePropTypes)),
  navigateTo: PropTypes.func
};
function mapDispatchToProps(dispatch) {
  return {
    navigateTo: function(url) {
      global.app.navigateToPage(dispatch, url);
    }
  };
}
export default connect(
  state => ({}),
  mapDispatchToProps
)(MagazineArchiveSearch);
export { MagazinePropTypes };
