import React from "react";
import PropTypes from "prop-types";

import { ReadMoreLink } from "../../atoms/read-more-link";
import { MagazineCard } from "../../atoms/magazine-buy-card";

import styles from "./styles.m.css";

export const MagazineRow = ({ issues, next = <ReadMoreLink href="#" />, className, accentColor }) => {
  if (!(issues && issues.length)) {
    return null;
  }
  return (
    <div className={`${styles["base"]} ${className}`} style={accentColor && { "--accent-color": accentColor }}>
      <div className={`container`}>
        <div className={`${styles["magazine-widget-wrapper"]}`}>
          <div className={`${styles.row} ${styles["magazine-widget"]}`}>
            {issues.map(issue => (
              <MagazineCard key={issue.id} collection={issue} className={styles["issue"]} />
            ))}
          </div>
          <div className={styles["button-container"]}>{next}</div>
        </div>
      </div>
    </div>
  );
};

MagazineRow.propTypes = {
  issues: PropTypes.arrayOf(PropTypes.shape(MagazineCard.propTypes)),
  next: PropTypes.element,
  className: PropTypes.string,
  accentColor: PropTypes.string
};

export default MagazineRow;
