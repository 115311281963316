import React from "react";
import PropTypes from "prop-types";

import Carousel from "../../atoms/carousel";

import MagazineBigCard from "../../atoms/magazine-big-card";
import Icon from "../../atoms/icon";
import styles from "./styles.m.css";
import { getStoryHeadline } from "../../utils/utils";
// import MagazineBigCard from "../magazine-big-card";

const MagazineSliderMain = ({ stories, className = "" }) => {
  stories = stories.filter(story => story.url && getStoryHeadline(story));
  if (stories.length === 0) {
    return null;
  }
  let slides = stories.map(story => (
    <MagazineBigCard className={styles.card} key={story.id} story={story} setmagazineUTM="magazine-page" />
  ));

  if (slides.length === 1) {
    return <div>{slides}</div>;
  }

  return (
    <Carousel
      className={`${styles["slider-main"]} ${className}`}
      options={{
        autoplay: 3000,
        type: "carousel"
      }}
      renderCenterLeftControls={({ previousSlide }) => (
        <button
          title="previous slide"
          aria-label="previous slide"
          role="button"
          onClick={previousSlide}
          className={`${styles["slider-nav-button"]} ${styles["prev-button"]}`}
        >
          <Icon type={"slider-prev"} />
        </button>
      )}
      renderCenterRightControls={({ nextSlide }) => (
        <button
          title="next slide"
          aria-label="next slide"
          role="button"
          onClick={nextSlide}
          className={`${styles["slider-nav-button"]} ${styles["next-button"]}`}
        >
          <Icon type={"slider-next"} />
        </button>
      )}
      renderBottomCenterControls={({ currentSlide, goToSlide }) => {
        return (
          <div className={styles.dots}>
            {stories.map((story, index) => (
              <button
                title="previous slide"
                aria-label="next slide"
                role="button"
                key={story.id}
                className={currentSlide === index ? `${styles["is-active"]}` : ``}
                onClick={() => {
                  goToSlide(index);
                }}
              />
            ))}
          </div>
        );
      }}
    >
      {slides}
    </Carousel>
  );
};

MagazineSliderMain.propTypes = {
  stories: PropTypes.arrayOf(MagazineBigCard.propTypes.story),
  className: PropTypes.string
};

export default MagazineSliderMain;
