import React from "react";
import MagazineArchiveSearch, { MagazinePropTypes } from "../../rows/magazine-archive-search";
import { MagazineCard } from "../../atoms/magazine-buy-card";
import { WebEngage, GA, PGAD } from "../../../integrations";
import styles from "./styles.m.css";
import PropTypes from "prop-types";
import { get } from "lodash";

import { PAGE_TYPE } from "../../../constants";

function MagazineSearchArchivePage({ data }) {
  React.useEffect(() => {
    WebEngage.registerPageView();
    GA.registerPageView(PAGE_TYPE.MAGAZINE_ARCHIVE_SEARCH_PAGE);
    PGAD.loadScript();
  }, []);
  const magazineIssues = get(data, ["magazineIssues"], []);
  const currentDate = new Date();
  const entity = get(data, ["entity"], null);
  let year = get(data, ["year"], null);
  let month = get(data, ["month"], null);
  month = month >= 0 && month < 12 ? month : year ? 0 : currentDate.getMonth();
  if (!year) {
    year = currentDate.getFullYear();
  }
  const magazines = get(data, ["magazines"], []);
  return (
    <React.Fragment>
      <MagazineArchiveSearch magazineEntity={entity} magazines={magazines} year={year} month={month} />
      <div className="main-page">
        <div />
        <div className={`container ${styles["wrap-list-section"]}`}>
          <MagazineList magazines={magazineIssues} />
        </div>
        <div />
      </div>
    </React.Fragment>
  );
}

MagazineSearchArchivePage.propTypes = {
  data: PropTypes.shape({
    entity: PropTypes.shape({
      id: PropTypes.number
    }),
    year: PropTypes.string,
    month: PropTypes.string,
    magazineIssues: PropTypes.arrayOf(PropTypes.shape(MagazinePropTypes.propTypes))
  })
};

const MagazineList = ({ magazines }) => {
  if (magazines && magazines.length === 0) {
    return <div className={styles["no-magazines-to-show"]}> No Magazines Found for the search Criteria</div>;
  }
  return (
    <div className={styles["magazine-list-section"]}>
      {magazines.map(magazine => (
        <MagazineCard collection={magazine} className={styles["card"]} key={magazine.id} />
      ))}
    </div>
  );
};

MagazineList.propTypes = {
  magazines: PropTypes.arrayOf(PropTypes.shape(MagazinePropTypes))
};

export default MagazineSearchArchivePage;
export { MagazineSearchArchivePage };
