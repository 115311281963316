import React from "react";
import { LazyLoadImages } from "@quintype/components";
import { MagazineLandingPage as MagazineLandingPageBase } from "../components/pages/magazine-landing";
import { MagazineIssueListingPage as MagazineIssueListingPageBase } from "../components/pages/magazine-listing-page";
import { MagazineSearchArchivePage as MagazineSearchArchivePageBase } from "../components/pages/magazine-archive-page";
import { MagazineLandingPageBundle as MagazineLandingPageBundlePageBase } from "../components/pages/magazine-landing-bundle";
import { TaboolaAd } from "../components/atoms/taboola-ad";
import { VikatanSectionHeader } from "../components/rows/ad-free";

function wrapLazyLoadImages(Template) {
  const ImageLazyLoadedComponent = props => {
    return (
      <React.Fragment>
        <VikatanSectionHeader />
        <LazyLoadImages margin={"150px"}>
          <Template {...props} />
        </LazyLoadImages>
        <div className="taboola-outer container">
          <TaboolaAd
            config={{
              mode: "thumbnails-a",
              id: "taboola-below-category-thumbnails",
              placement: "Below Category Thumbnails"
            }}
            url={global && global.location && global.location.href}
            className={"taboola-article"}
            directory={"category"}
          />
        </div>
      </React.Fragment>
    );
  };
  ImageLazyLoadedComponent.displayName = `WrapLazyLoadImages(${Template.name})`;
  return ImageLazyLoadedComponent;
}

export const MagazineLandingPage = wrapLazyLoadImages(MagazineLandingPageBase);
export const MagazineLandingPageBundlePage = wrapLazyLoadImages(MagazineLandingPageBundlePageBase);
export const MagazineIssueListingPage = wrapLazyLoadImages(MagazineIssueListingPageBase);
export const MagazineSearchArchivePage = wrapLazyLoadImages(MagazineSearchArchivePageBase);
