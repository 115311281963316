import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import MagazineSixColumnGridBundle from "../../rows/magazine-6column-grid-bundle/index";
import styles from "./styles.m.css";
import { WebEngage, GA, DeepBI, PGAD } from "../../../integrations";
import MagazineIssueOneSliderThreeCardsBundle from "../../rows/magazine-issue-one-slider-3cards-bundle/index";
import FocusedCardbundle from "../../atoms/focused-card-bundle/index";
import { VikatanSticky } from "../../rows/ad-free";

const getStoriesByLabel = collection => {
  return collection.items.filter(({ story = {} }) => story.headline && story.url).reduce((storyMap, item) => {
    const label = get(item, ["associated-metadata", "label"], "மற்றவை");
    if (storyMap[label]) {
      storyMap[label].push(item.story);
    } else {
      storyMap[label] = [item.story];
    }
    return storyMap;
  }, {});
};

const getStoriesWithLabelOnFirst = storyMap => {
  let stories = [];
  Object.keys(storyMap).forEach(key => {
    const tempStories = storyMap[key];
    if (tempStories.length) {
      tempStories[0].label = key;
    }
    stories = stories.concat(tempStories);
  });
  return stories;
};

export const MagazineLandingPageBundle = ({
  pageType,
  config,
  data: { collection, magazine, nextIssue, prevIssue }
}) => {
  React.useEffect(() => {
    WebEngage.registerPageView();
    GA.registerPageView(pageType || "magazine-bundle-page", { collection });
    DeepBI.registerPageView({ collection: { ...collection, pageType: pageType || "magazine-bundle-page" } });
    PGAD.loadScript();
  }, []);
  if (!collection) return null;
  const storyMap = getStoriesByLabel(collection);
  const stories = getStoriesWithLabelOnFirst(storyMap);

  return (
    <React.Fragment>
      <div className={styles["base"]}>
        <div className="container">
          <div className={styles["bundle-grid"]}>
            <div className={styles["main-content"]}>
              <MagazineIssueOneSliderThreeCardsBundle
                collection={collection}
                nextIssue={nextIssue}
                prevIssue={prevIssue}
                config={config}
              />
              <MagazineSixColumnGridBundle stories={stories} />
            </div>
            <aside>
              <div className={`${styles["ad-wrapper"]} hidden-mobile`}>
                {stories.slice(5, 8).map((story, index) => (
                  <FocusedCardbundle key={story.id} story={story} className={styles["story-card"]} />
                ))}
              </div>
            </aside>
          </div>
        </div>
      </div>
      <VikatanSticky placement="bundle" />
    </React.Fragment>
  );
};

MagazineLandingPageBundle.propTypes = {
  pageType: PropTypes.string,
  config: PropTypes.shape({
    contentInsightsId: PropTypes.string,
    magazines: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        magazineSlug: PropTypes.string,
        name: PropTypes.string,
        url: PropTypes.string,
        vikatanMagazineId: PropTypes.number
      })
    ),
    "publisher-name": PropTypes.string
  }),
  data: PropTypes.shape({
    oldIssues: PropTypes.arrayOf(PropTypes.any),
    config: PropTypes.shape({
      section: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.string,
          slug: PropTypes.string
        })
      ),
      magazines: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          magazineSlug: PropTypes.string,
          name: PropTypes.string,
          url: PropTypes.string,
          vikatanMagazineId: PropTypes.number
        })
      ),
      "publisher-name": PropTypes.string
    }),
    collection: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.string,
          "associated-metadata": PropTypes.shape({
            label: PropTypes.string,
            "page-no": PropTypes.string
          }),
          story: PropTypes.shape({
            id: PropTypes.string,
            contributors: PropTypes.arrayOf(
              PropTypes.shape({
                "role-name": PropTypes.string,
                authors: PropTypes.arrayOf(
                  PropTypes.shape({
                    name: PropTypes.string
                  })
                )
              })
            ),
            headline: PropTypes.string.isRequired,
            alternative: PropTypes.shape({
              home: PropTypes.shape({
                default: PropTypes.shape({
                  headline: PropTypes.string
                })
              })
            }),
            slug: PropTypes.string.isRequired,
            "hero-image-metadata": PropTypes.shape({
              height: PropTypes.number,
              width: PropTypes.number,
              "aspect-ratio": PropTypes.arrayOf(PropTypes.number)
            }),
            "hero-image-s3-key": PropTypes.string,
            "hero-image-caption": PropTypes.string
          })
        })
      )
    })
  })
};

export default MagazineLandingPageBundle;
