import React from "react";
import PropTypes from "prop-types";
import { VikatanHomeCollectionResponsive } from "../../rows/ad-free";
import { get } from "lodash";

const AdUnit = props => {
  const index = get(props, ["index"], 0);
  let DesktopunitName =
    index === 4
      ? "Vikatan_Desktop_HP_MID_728x90"
      : index === 6
        ? "Vikatan2023_Desktop_HP_MID2_970x90"
        : index === 8
          ? "Vikatan2023_Desktop_HP_MID3_970x90"
          : index === 10
            ? "Vikatan2023_Desktop_HP_BTF1_970x90"
            : index === 12
              ? "Vikatan2023_Desktop_HP_BTF2_970x90"
              : index === 14
                ? "Vikatan2023_Desktop_HP_BTF3_970x90"
                : index === 16
                  ? "Vikatan2023_Desktop_HP_BTF4_970x90"
                  : index === 18
                    ? "Vikatan2023_Desktop_HP_BTF4_970x90"
                    : index === 20
                      ? "Vikatan_Desktop_HP_BTF3_728x90"
                      : "Vikatan2023_Desktop_HP_MID_970x90";

  let MobileunitName =
    index === 4
      ? "Vikatan2023_Mobile_HP_MID3_336x280"
      : index === 6
        ? "Vikatan2023_Mobile_HP_BTF1_336x280"
        : index === 8
          ? "Vikatan2023_Mobile_HP_BTF2_336x280"
          : index === 10
            ? "Vikatan2023_Mobile_HP_BTF3_336x280"
            : index === 12
              ? "Vikatan2023_Mobile_HP_BTF4_336x280"
              : index === 14
                ? "Vikatan2023_Mobile_HP_BTF4_336x280"
                : index === 16
                  ? "Vikatan2023_Mobile_HP_BTF4_336x280"
                  : index === 18
                    ? "Vikatan2023_Mobile_HP_BTF4_336x280"
                    : index === 20
                      ? "Vikatan2023_Mobile_HP_BTF8_336x280"
                      : "Vikatan2023_Mobile_HP_BTF_320x280";
  return (
    <div>
      <VikatanHomeCollectionResponsive
        DesktopAdtype={DesktopunitName}
        MobileAdtype={MobileunitName}
        placement={props.placement}
        adtype={index}
      />
    </div>
  );
};

AdUnit.propTypes = {
  placement: PropTypes.string,
  index: PropTypes.number
};

export default AdUnit;
