import React from "react";
import PropTypes from "prop-types";
import { Link, WithHostUrl } from "@quintype/components";
import { Icon } from "../../atoms/icon";

import styles from "./styles.m.css";

const Contributor = ({ path, type, name, imgUrl, className = "", config = {} }) => {
  if (!name) {
    return null;
  }
  const ContributorRoles = {
    "art-director": "art-director",
    author: "author",
    anchor: "anchor",
    cartoonist: "cartoonist",
    illustrator: "illustrator",
    "food-stylist": "food-stylist",
    infographer: "infographer",
    photographer: "photographer",
    videographer: "videographer",
    "published-by": "published-by",
    producer: "producer",
    "script-writer": "script-writer",
    stylist: "stylist",
    scripted: "scripted",
    "video-editor": "video-editor",
    sponsored: "sponsored",
    advertorial: "advertorial",
    vikatanconnect: "vikatanconnect",
    brandconnect: "brandconnect",
    exclusivedeals: "exclusivedeals",
    authorwhite: "authorwhite"
  };
  type = type && type.replace(" ", "-").toLowerCase();
  const iconType = type && ContributorRoles[type] ? ContributorRoles[type] : "author";
  return path && !config.disableAuthorLink ? (
    <WithHostUrl>
      {({ primaryHostUrl }) => (
        <Link aria-label="Author" href={`${primaryHostUrl}${path}`} className={`${styles["base"]} ${className}`}>
          {imgUrl && <img src={imgUrl} />}
          {iconType && <Icon type={iconType} className={styles["icon"]} />}
          <span className={styles["contributor-name"]}>{name}</span>
        </Link>
      )}
    </WithHostUrl>
  ) : (
    <div className={`${styles["base"]} ${className}`}>
      {imgUrl && <img src={imgUrl} />}
      {iconType && <Icon type={iconType} className={styles["icon"]} />}
      <span className={styles["contributor-name"]}>{name}</span>
    </div>
  );
};

Contributor.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string,
  imgUrl: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  config: PropTypes.shape({
    disableAuthorLink: PropTypes.bool
  })
};

export default Contributor;
